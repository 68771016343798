<template>
  <div id="bg">
    <search-pic :type="type" :title="title">
      <div style="display: flex">
        <div class="left-box">
          <div>
            <dv-border-box-13 style="padding: 10px;height: 100% ">
              <div style="padding: 10px;font-size: 18px;color: #fff;font-weight: 500">
                电梯安装品牌
              </div>
              <div id="maintainElevator"></div>
            </dv-border-box-13>
          </div>
          <div>
            <dv-border-box-12 style="height: 200px">
              <div style="padding: 20px;font-size: 18px;color: #fff;font-weight: 500">
                电梯安装在线情况
              </div>
              <div style="display: flex">
                <div style="margin: 30px;font-size: 18px;color: #fff;font-weight: 500">
                  <p><img class="logo" src="~@/assets/img/newBigScreen/矢量智能对象_7@2x.png"/>电梯安装数</p>
                  <p style="color: #01B9D9;font-size: 32px;margin-top: 10px">{{formatter(terminal.deviceInstallQuantity)}}</p>
                </div>
                <div style="margin: 30px;font-size: 18px;color: #fff;font-weight: 500">
                  <p><img class="logo" src="~@/assets/img/newBigScreen/矢量智能对象_8@2x.png"/>电梯在线数</p>
                  <p style="color: #01B9D9;font-size: 32px;margin-top: 10px">{{formatter(terminal.deviceOnlineQuantity)}}</p>
                </div>
                <div id="terminal" style="width: 120px;height:120px;margin-top: -5px"></div>
              </div>
            </dv-border-box-12>
          </div>
          <div>
            <!--            <dv-border-box-12>-->
            <!--              <div style="padding: 10px;font-size: 18px;color: #fff;font-weight: 500">-->
            <!--                维修保养完成情况-->
            <!--              </div>-->
            <!--              <div style="display: flex">-->
            <!--                <div id="complete1" style="width: 200px"></div>-->
            <!--                <div id="complete2" style="width: 200px"></div>-->
            <!--                <div id="complete3" style="width: 200px"></div>-->
            <!--              </div>-->
            <!--            </dv-border-box-12>-->

            <dv-border-box-12 class="realTimeBox" style="padding: 10px ">
              <div style="padding: 20px;font-size: 18px;color: #fff;font-weight: 500">
                实时运行情况
              </div>
              <div id="realTime">
                <dv-scroll-board id="realTimeBox" :config="config" style="width:500px;margin: 0 auto"></dv-scroll-board>
              </div>
            </dv-border-box-12>
          </div>
        </div>
        <div class="center-box">
          <center></center>
          <centerceft2chart width="100%" height="570px"></centerceft2chart>
        </div>
        <div class="right-box">
          <div>
            <dv-border-box-12 style="padding: 10px ">
              <div id="teamwork"></div>
            </dv-border-box-12>
          </div>
          <div>
            <dv-border-box-12 style="padding: 10px;height: 412px ">
              <div>
                <span style="padding: 20px;font-size: 18px;color: #fff;font-weight: 500;display: inline-block">今日工单数</span>
                <el-button-group style="float: right;margin-top: 15px;margin-right: 10px">
                  <el-button
                    type="primary"
                    class="orderButton"
                    :disabled="buttonType==='保养'"
                    @click="changeOrder('保养')">
                    保养
                  </el-button>
                  <el-button
                    class="orderButton"
                    type="primary"
                    :disabled="buttonType==='维修'"
                    @click="changeOrder('维修')">
                    维修
                  </el-button>
                  <el-button
                    class="orderButton"
                    type="primary"
                    :disabled="buttonType==='检测'"
                    @click="changeOrder('检测')">
                    检测
                  </el-button>
                </el-button-group>
              </div>

              <dv-scroll-board :config="configOrder" style="height: 320px;"></dv-scroll-board>
            </dv-border-box-12>
          </div>
        </div>
      </div>
    </search-pic>
  </div>
</template>

<script>
  import SearchPic from "@/views/template/SearchPic";
  import centerceft2chart from "@/components/echart/centerLeft/centerLeft2Chart";
  import center from "./center";
  import * as echarts from "echarts";
  import tdTheme from "@/common/echart/theme.json";
  import loginUtil from "@/util/loginUtil";

  export default {
    components:{SearchPic,centerceft2chart,center},
    data() {
      return{
        buttonType:"保养",
        filter: {
          name: "",
          regCode: "",
          factoryCode: "",
          rescueCode: "",
          type: "",
          status: "",
          projectName: "",
          deviceNo: "",
        },
        // title:loginUtil.getUserInfo().employee.companyName+"监控中心"||"梯智物联监控中心",
        title:"梯智物联监控中心",
        type: {
          axisBar: "axisBar",//折线柱状图
          defaultLine: "defaultLine",//默认的折线图
          radarchart: "RadarChart",//雷达图
          sankey: "sankey",//桑基图
          scatter: "scatter",//气泡图
          stackCharts: "stackCharts",
          sunburst: "sunburst",
          wordcloud: "wordcloud",
          ybar: "ybar",
          pie:"pie",
          bar:"bar",
        },
        option:{
          indicator:{},
        },
        terminal:{},
        config:{
          data:[],
          header: ["<span>楼盘</span>", "<span>楼宇</span>", "<span>状态</span>"],
          rowNum:10,
          oddRowBGC:"#050e49",
          evenRowBGC:"#050e49",
        },
        configOrder:{
          header: ["<span>工单号</span>", "<span>维保班组</span>", "<span>类型</span>","<span>状态</span>"],
          data: [
            ["行1列1", "行1列2", "行1列3", "行1列3"],
            ["行1列1", "行1列2", "行1列3", "行1列3"],
            ["行1列1", "行1列2", "行1列3", "行1列3"],
            ["行1列1", "行1列2", "行1列3", "行1列3"],
            ["行1列1", "行1列2", "行1列3", "行1列3"],
            ["行1列1", "行1列2", "行1列3", "行1列3"],
            ["行1列1", "行1列2", "行1列3", "行1列3"],
            ["行1列1", "行1列2", "行1列3", "行1列3"],

          ],
          rowNum:8,
          oddRowBGC:"#050e49",
          evenRowBGC:"#050e49",
        },
        list:[],
        terminalTime:null,
      };
    },
    mounted() {
      console.log(window.innerHeight,127);
      this.getData();
      this.getTerminalData();
      this.getTeamData();
      this.getCompleteData();

      this.getRealTime();
      this.getOrderData();
      this.timer=window.setInterval(()=>{
        // this.getTerminalData();
        this.getTeamData();
        this.getRealTime();
        this.getOrderData();
      },10000);
      this.terminalTime=window.setInterval(()=>{
        // console.log(186);
        this.getTerminalData();
      },30000);
    },
    beforeDestroy() {
      window.clearInterval(this.timer);
      window.clearInterval(this.terminalTime);
      this.timer=null;
    },
    methods:{
      changeOrder(type) {
        this.buttonType=type;
        const arr = [];

        if (this.buttonType==="保养"&&this.list instanceof Array) {
          const list = this.list.filter(item=>item.maintenanceType==="保养");
          list.forEach(item=>{
            arr.push([item.orderNo,item.teamName,item.orderType,item.orderStatus]);
          });
        }else if (this.buttonType==="维修"&&this.list instanceof Array) {
          const list = this.list.filter(item=>item.maintenanceType==="维修");
          list.forEach(item=>{
            arr.push([item.orderNo,item.teamName,item.orderType,item.orderStatus]);
          });
        }

        this.configOrder.data=arr;
        // this.config.data=arr;
        this.configOrder = {...this.configOrder};
      },
      getData() {
        let http =   this.$http.get("/big-screen/maintenance-elevator-brand",{maintenanceType:"as_need"});
        http.then(data => {
          if (data.brandQuantities && data.brandQuantities instanceof Array) {
            const initNum=100;
            const arr=  [];
            const list = data.brandQuantities.sort(function(a,b) {
              return b.elevatorQuantity-a.elevatorQuantity;
            });
            list.forEach((item,index)=>{
              if (index<initNum) {
                arr.push({name:item.brandName,
                          id:index,
                          value:item.elevatorQuantity,
                          elevatorProp:item.elevatorProp});
              }else if (index===initNum) {
                arr.push({name:"其他",
                          id:index,
                          value:item.elevatorQuantity,
                          elevatorProp:item.elevatorProp});
              }else {
                arr[5].value+=item.elevatorQuantity;
              }

            });
            window.setTimeout(()=>{
              this.drawPic(arr);
            },1000);

          }
        }).catch(() => {
          // this.status = "error";
        });
      },
      getTeamData() {
        let http =   this.$http.get("/big-screen/team-maintenance-statistics",{maintenanceType:"as_need",companyId: loginUtil.getCompanyCode()});
        http.then(data => {
          if (data instanceof Array) {
            const initNum=data.length;
            const name=  [];
            const data1=  [];
            const data2=  [];

            const list = data.sort(function(a,b) {
              return (a.repairQuantity+a.maintainQuantity)-(b.repairQuantity+b.maintainQuantity);
            });

            console.log(list,258);
            list.forEach((item,index)=>{
              if (index<initNum&&index>=initNum-15) {
                name.push(item.teamName);
                data1.push(item.repairQuantity);
                data2.push(item.maintainQuantity);
              }
            });

            window.setTimeout(()=>{
              this.drawTeamPic(data1,data2,name);
            },1000);

          }
        }).catch(() => {
          // this.status = "error";
        });
      },
      getCompleteData() {
        let http =   this.$http.get("/big-screen/repair-maintain-check/completeRate",{maintenanceType:"as_need",companyId: loginUtil.getCompanyCode()});
        http.then(data => {
          window.setTimeout(()=>{
            this.drawComplete1Pic(data.repairCompleteRate,1,"维修");
            this.drawComplete1Pic(data.maintainCompleteRate,2,"保养");
            this.drawComplete1Pic(data.testCompleteRate,3,"检测");
          },2000);
        }).catch(() => {
          // this.status = "error";
        });
      },
      getTerminalData() {
        let http =   this.$http.get("/big-screen/elevator-device-statistics",{maintenanceType:"as_need"});
        http.then(data => {
          this.terminal=data;
          window.setTimeout(()=>{
            this.drawTerminal(data.onlineRate);

          },1000);
        }).catch(() => {
          // this.status = "error";
        });
      },
      drawPic(data) {
        const chart = echarts.init(document.querySelector("#maintainElevator"),tdTheme);
        this.chart = chart;
        let count = 0;
        data.forEach(item=>{
          count+=item.value;
        });
        const option = {
          tooltip: {
            trigger: "item",
          },
          // legend: {
          //   type: "scroll",
          //   orient: "vertical",
          //   right: 10,
          //   top: 20,
          //   bottom: 20,
          // },
          // title: {
          //   text: "在保电梯数据统计",
          //   textStyle:{
          //     color:"#fff",
          //     fontSize:"18px",
          //   },
          //
          // },
          title: {
            show: true,
            text: "全部",
            subtext: count,
            top: 110,
            left: 270,
            textAlign: "center",
            z: 0,
            textStyle: {
              color:"white",
              fontSize:"20px",
              fontWeight:700,
            },
            subtextStyle:{
              color:"white",
              fontSize:"20px",
              fontWeight:700,
            },
          },
          series: [
            {
              // name: this.name,
              name:"品牌",
              type: "pie",
              avoidLabelOverlap: false,
              // label: {
              //   normal: {
              //     // 此处重点，设置为显示
              //     show: true,
              //     position: "center",
              //     formatter: "{b}\n{c}",
              //     // align: "center",
              //     // verticalAlign: "middle",
              //     // 此处重点，字体大小设置为0
              //     textStyle: {
              //       fontSize: "0",
              //     },
              //   }},
              label:{
                formatter: ({data})=>{
                  return data.name+"-"+data.elevatorProp;
                },
                textStyle:{
                  color:"white",
                  fontSize:"16px",

                },
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: "20",
                  fontWeight: "bold",
                  textStyle:{
                    color:"white",
                  },
                },
              },
              labelLine: {
                show: false,
                fontSize: "20",
                fontWeight: "bold",
              },
              data: data,
            },
          ],
        };
        option.series[0].radius = ["40%", "70%"];

        this.chart.setOption(option);
        // this.chart.on("mouseover", { seriesName: "品牌" }, () => {
        //   console.log(449);
        //   this.chart.setOption({
        //     title: {
        //       show: false,
        //     },
        //   });
        // });
        //
        // this.chart.on("mouseout", { seriesName: "品牌" }, () => {
        //   console.log(449);
        //   this.chart.setOption({
        //     title: {
        //       show: true,
        //     },
        //   });
        // });
      },
      drawTeamPic(data1,data2,name) {
        const chart = echarts.init(document.querySelector("#teamwork"),tdTheme);
        const option =  {
          title: {
            text: "维保队工作统计",
            textStyle:{
              color:"#fff",
              fontSize:"18px",
            },
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              // Use axis to trigger tooltip
              type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
            },
          },
          legend: {},
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "value",
            nameTextStyle:{
              overflow:"breakAll",
            },
            show:false,
          },
          yAxis: {
            type: "category",
            nameTextStyle:{
              overflow:"breakAll",
            },
            axisLabel: { interval: 0, rotate: 0 },
            axisTick:{alignWithLabel:true},
            data: name,
          },
          series: [
            {
              name: "维修",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: data1,
            },
            {
              name: "保养",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: data2,
            },

          ],
        };
        option.series[0].radius = ["40%", "70%"];

        chart.setOption(option);
      },
      drawTerminal(value) {
        const chart = echarts.init(document.querySelector("#terminal"),tdTheme);

        const gaugeData = [
          {
            value,
            // name: "Perfect",
            // title: {
            //   offsetCenter: ["0%", "-30%"],
            // },
            // detail: {
            //   valueAnimation: true,
            //   offsetCenter: ["0%", "-20%"],
            // },
          },

        ];
        const option = {
          series: [
            {
              type: "gauge",
              startAngle: 90,
              endAngle: -270,
              pointer: {
                show: false,
              },
              progress: {
                show: true,
                overlap: false,
                roundCap: true,
                clip: false,
                itemStyle: {
                  borderWidth: 2,
                  color: "#E8BA1F",
                },
              },
              splitLine: {
                show: false,
                distance: 0,
                length: 10,
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                show: false,
                distance: 50,
              },
              data: gaugeData,
              title: {
                fontSize: 14,
              },
              detail:{
                color:"white",
                textStyle:{
                  fontSize:"18px",
                },
                formatter:function(parmas) {
                  return parmas.toFixed(1)+"%";
                },
              },
            },
          ],
        };
        chart.setOption(option);
      },
      formatter(number) {
        const str = number+"";
        const numbers = str.split("").reverse();
        const segs = [];

        while (numbers.length) segs.push(numbers.splice(0, 3).join(""));

        return segs.join(",").split("").reverse().join("");
      },
      getRealTime() {
        let http =   this.$http.get("/big-screen/device-real-time-data/list");
        http.then(data => {
          const arr = [];
          if (data instanceof Array) {
            data.forEach((item)=>{
              if (!item.online) {
                arr.push([item.realEstateName,item.elevatorName,"<span style=\"color:#3F78BC\">离线</span>"]);
              }
            });
          }

          this.config.data=arr;
          this.config = {...this.config};
        }).catch(() => {
          // this.status = "error";
        });
      },
      getOrderData() {
        let http =   this.$http.get("/big-screen/today-order/list",{maintenanceType:"as_need"});
        http.then(data => {
          console.log(data,493);
          this.list= data;
          const arr = [];

          if (this.buttonType==="保养"&&data instanceof Array) {
            const list = data.filter(item=>item.maintenanceType==="保养");
            list.forEach(item=>{
              arr.push([item.orderNo,item.teamName,item.orderType,item.orderStatus]);
            });
          }else if (this.buttonType==="维修"&&data instanceof Array) {
            const list = data.filter(item=>item.maintenanceType==="维修");
            list.forEach(item=>{
              arr.push([item.orderNo,item.teamName,item.orderType,item.orderStatus]);
            });
          }

          this.configOrder.data=arr;
          // this.config.data=arr;
          this.configOrder = {...this.configOrder};
        }).catch(() => {
          // this.status = "error";
        });
      },
      getOrder() {
        let http =   this.$http.get("/big-screen/device-real-time-data/list");
        http.then(data => {
          const arr = [];
          if (data instanceof Array) {
            data.forEach((item)=>{
              if (!item.online) {
                arr.push([item.realEstateName,item.elevatorName,"<span style=\"color:#3F78BC\">离线</span>"]);
              }else if (!item.isMaintain&&!item.isRepair) {
                arr.push([item.realEstateName,item.elevatorName,"<span style=\"color:#48BD66\">正常</span>"]);
              }else if (item.isRepair) {
                arr.push([item.realEstateName,item.elevatorName,"<span style=\"color:#F21250\">维修</span>"]);
              }else {
                arr.push([item.realEstateName,item.elevatorName,"<span style=\"color:#FAAD14\">保养</span>"]);
              }


            });
          }

          this.config.data=arr;
          this.config = {...this.config};
        }).catch(() => {
          // this.status = "error";
        });
      },

    },
  };
</script>

<style scoped>
#bg{
  width: 100%;
  height: 100%;
  padding: 16px 16px 0 16px;
  background-image: url("../../assets/img/newBigScreen/组 24.png");
  background-size: cover;
  background-position: center center;
}
.left-box{
  width: 30%;
  /*background-color: #0f1325;*/
}
.center-box{
  width: 39%;
}
.right-box{
  width: 30%;
}
</style>
<style scoped lang="scss">
@import "../../assets/scss/bigscreen.scss";

#maintainElevator{
  height:280px
}
#teamwork{
  height: 530px;
}
#complete1{
  height: 100vh * 0.3;
}
#complete2{
  //width: 100px;
  height: 100vh * 0.3;
}
#complete3{
  height: 100vh * 0.3;
}
#terminal{
  height: 100vh * 0.2;
}
.logo{
  width: 18px;line-height: 18px
}
#realTime{
  height: 380px;
}
#realTimeBox{
  height: 320px;
}
.realTimeBox{
  height: 420px;
}
.orderButton{
  width: 68px;
  height: 28px;
  border: 1px solid #4cadff;
  font-size: 16px;
  border-radius: 0px 2px 2px 0px;
}
</style>
